<template>
  <div class="delivery-date-container">
        <button @click.prevent="requestPermission"  class="primary_button">
          <i class="fa fa-bell" aria-hidden="true"></i>
          Enable Notifications
        </button>
        <div v-if="message" class="date_description">
             {{ message }}
        </div>
    </div>
</template>

<script>
import { getMessaging, getToken } from 'firebase/messaging';
import { Vapid_Key,Users_Collection } from '../global_config';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

export default {
  props: {
    All_UserData: Object
  },
  data() {
    return {
      message: '',
      Vapid_Key_Apply: Vapid_Key,
      Users_Collection_Apply: Users_Collection
    };
  },

  created(){
      //this.requestPermission();
  },

  methods: {
    async requestPermission() {
      const messaging = getMessaging();
      this.message = 'Please wait...';

      try {
        const token = await getToken(messaging, { vapidKey: this.Vapid_Key_Apply });

        if (token) {
          this.message = 'Notifications enabled';
          //this.message = 'Notification permission granted and token received.';
          console.log('FCM Token:', token);

          // Store in localStorage first (for users without accounts)
          localStorage.setItem('User_Notification_ID', token);
          this.$emit('FCM_Token_Obtained', token);

          // Store for users with accounts
          if (this.All_UserData) {
            const userRole = this.All_UserData.User_Role;
            if (userRole === 'SendSm1 Shopper' || userRole === 'SendSm1 Customer') {
              const db = getFirestore();
              const userRef = doc(db, this.Users_Collection_Apply, this.All_UserData.User_ID);
              await updateDoc(userRef, {
                User_Notification_ID: token
              });
              console.log('Notification token updated in database');
            }
          }
        } else {
          //this.message = 'No registration token available. Request permission to generate one.';
        }
      } catch (error) {
        console.error('Error getting notification token:', error);
        this.message = 'Error, click again or skip it';
      }
    }
  }
};
</script>


<style scoped>
.delivery-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .date_description {
    padding: var(--spacing-main);
    font-size: var(--font-size-medium);
    font-weight: 600;
    background-color: var(--accent);
    width: 100%;
    border-radius: var(--border-radius-main);
    box-sizing: border-box;
    text-align: center;
  }
</style>
