<template>
      <div>
    
        <template v-if="loading">
           <img src="../assets/Loading.gif" class="Loading"/>
        </template>

        <template v-else>

                <main_notification_permission :All_UserData="All_UserData"/>
                    
                <div v-if="userData">
                        <template v-if="userData?.User_Role === 'SendSm1 Shopper'">
                    
                        </template>

                        <template v-else>

                        </template>
                </div>
            
                <template v-else>
                        <!--When logged out-->
                </template>
    
        </template>
    
      </div>    
    </template>
    
    <script>
    import { getAuth, onAuthStateChanged } from 'firebase/auth';
    import { mapState, mapActions } from 'vuex';
    import main_notification_permission from './main_notification_permission.vue';
    //import test_orders from './test_orders.vue';
    
    export default {
      
      components: {
          main_notification_permission,
      },
      computed: {
        ...mapState(['userData', 'userAuthenticated'])
      },
      data() {
        return {
          loading: true,
          isLoggedIn: false,
          All_UserData: {},
        };
      },
      created() {
        this.checkAuthentication();
      },
    
    
      methods: {
          ...mapActions(['fetchUserData']),
          async checkAuthentication() {
            const auth = getAuth();
    
            onAuthStateChanged(auth, async (user) => {
              if (!user) {
                //
              } else {
                if (!this.userData) {
                  await this.fetchUserData();
                  console.log("Fetched");
                }
                this.isLoggedIn = true; // Set isLoggedIn to true if user is authenticated
    
                this.All_UserData = this.userData;
              }
    
              this.loading = false;
    
    
            });
          },
    
        }
    
    };
    </script>
    
    